import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CourseList.css';
import logo from './logo.png';

const CourseList = ({ audienceSlug }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    fetch(`https://xn--b1adeadlc3bdjl.online/api/courses/${audienceSlug}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
	const sortedCourses = data.sort((a, b) => a.id - b.id);
        setCourses(data);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  }, [audienceSlug]);

  return (
    <div className='course-list'>
      <div class="box1"></div>
      {courses.map((course, index) => (
        <div key={course.id} className='course-item'>
          <Link to={`/course/${course.slug}?index=${index + 1}`}>
            <div className='course-step'>{index + 1}</div>
            <img src={logo} alt='logo' className='course-logo' />
            <h2 className='course-title'>{course.title}</h2>
            <p className='course-description'>{course.description}</p>
            <img src={course.image} alt={`${course.title} logo`} className='course-icon' />
          </Link>
        </div>
      ))}
      <div className="box2"></div>
    </div>
  );
};

export default CourseList;
