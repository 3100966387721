import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import YoutubeEmbed from '../../components/YoutubeEmbed/YoutubeEmbed';
import './CourseDetail.css';

const CourseDetail = () => {
  const { slug } = useParams();
  const [course, setCourse] = useState(null);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lessonNumber = queryParams.get('index');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      fetch(`https://xn--b1adeadlc3bdjl.online/api/course/${slug}/view/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log('Views updated:', data);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
    } else {
      console.error('No token found');
    }
  
    fetch(`https://xn--b1adeadlc3bdjl.online/api/course/${slug}/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        setCourse(data);
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
  }, [slug]);

  const handleNextVideo = () => {
    if (course && course.audience) {
      fetch(`https://xn--b1adeadlc3bdjl.online/api/course/${slug}/next/${course.audience.slug}/`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.slug) {
	    const nextLessonIndex = parseInt(new URLSearchParams(window.location.search).get('index')) + 1;
            navigate(`/course/${data.slug}?index=${nextLessonIndex}`);
	    window.location.reload();
          } else {
            console.error("Next course slug is undefined");
          }
        })
        .catch(error => {
          console.error('Fetch error:', error);
        });
    }
  };

  if (!course) return <div>Loading...</div>;
 
  const videoUrl = course.video_file ? course.video_file : null;


  return (
    <div key={slug} className="course-detail">

	<div className="course_name">
          <p> {lessonNumber}. {course.title} {course.description} </p>
        </div>

      {videoUrl ? (
        <div className="video-responsive">
        <video controls>
           <source src={videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
        </video>
      </div>
      ) : (
        <YoutubeEmbed embedId={course.video_id} />
      )}
      <div className="course-buttons-container">
        <div className="course-buttons">
          {course.files_link && (
            <a href={course.files_link} target="_blank" rel="noopener noreferrer" className="button files-button">
              ДОПОЛНИТЕЛЬНЫЕ МАТЕРИАЛЫ
            </a>
          )}
          <button onClick={handleNextVideo} className="button next-button">СЛЕДУЮЩИЙ УРОК</button>
        </div>
        <img src="/images/newspaper.png" alt="Next Lesson" className="background-image" />
      </div>
    </div>
  );
};

export default CourseDetail;

