import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-left">
                    <p>Сотрудничество<br />
                    <a href="mailto:pro.dvizheniye@internet.ru">pro.dvizheniye@internet.ru</a></p>
                    <p>Связь с организаторами<br />
                    <a href="tel:+79378352185">+7 937 835 2185</a><br />
                    <a href="mailto:ilwina.zar@yandex.ru">ilwina.zar@yandex.ru</a></p>
                </div>
                <div className="footer-center">
                    <img src="./prodvizhenie.png" alt="Logo" className="footer-logo-image" />
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; «ПРОдвижение» 2024</p>
            </div>
        </footer>
    );
}

export default Footer;
